import * as orders from "./orders";
import * as giftcards from "./giftcards";
import * as categories from "./categories";
import * as setting from "./setting";
import * as collection from "./collection";
import * as brands from "./brands";

export const gshop2 = {
    ...orders,
    ...giftcards,
    ...categories,
    ...setting,
    ...collection,
    ...brands
};
