import axios from "@/plugins/axios";

let url = "/api/console/gshop2/brands";

export const brands = {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    thumb: {
        post(_brand, thumb){
            let formData = new FormData();
            formData.append("file", thumb);
            return axios.post(`${url}/${_brand}/thumb`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(result => result.data);
        }
    }
}