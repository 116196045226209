import axios from "@/plugins/axios";

let url = "/api/v1/user/me/gshop2/purchases";

export const purchases = {
    cancel: {
        post(data){
            return axios.post(`${url}/${data._id}/cancel`, data).then(result => result.data);
        }
    }
}