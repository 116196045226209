import axios from "@/plugins/axios";

let url = "/api/v1/gshop2/brands";

export const brands = {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
}