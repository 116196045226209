import axios from "@/plugins/axios";
import CryptoAES from "@/plugins/crypto-aes";
import * as me from "./me";

let url = "/api/v1/user";

export const user = {
    ...me,

    join: {
        post(data){
            let encrypted = {};
            for(let key of Object.keys(data)){
                if(typeof data[key] == "string") encrypted[key] = CryptoAES.encrypt(data[key]);
            }

            return axios.post(`${url}/join`, encrypted).then(result => result.data);
        }
    },
    certify: {
        post(data){
            return axios.post(`${url}/certify`, data).then(result => result.data);
        },
    },
    repair: {
        post(data){
            return axios.post(`${url}/repair`, data).then(result => result.data);
        },
    },

    withdraw: {
        post(data){
            return axios.post(`${url}/withdraw`, data).then(result => result.data);
        }
    }
}
