import axios from "@/plugins/axios";
import CryptoAES from '@/plugins/crypto-aes';

let url = "/api/plugins/nanuri/cash";

export default {
    auth: {
        getRefreshToken({ username, password }){
            var basicToken = "Basic " + CryptoAES.encrypt( new Buffer(`${username}:${password}`).toString('base64') );

            return axios.get(`${url}/auth/refreshToken`, {
                headers: { "Authorization": basicToken }
            }).then(result => result.data);
        },
    },

    charge({ _charge, refreshToken }){
        return axios.post(`${url}/charge`, { _charge }, { headers: { "Authorization": refreshToken } }).then(result => result.data);
    },
    
    charge2({ _order, refreshToken }){
        return axios.post(`${url}/charge2`, { _order }, { headers: { "Authorization": refreshToken } }).then(result => result.data);
    },
}