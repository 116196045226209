import bandBanners from "./bandBanners";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import gshop from "./gshop";
import me from "./me";
import promoter from "./promoter";
import setting from "./setting";
import shop from "./shop";
import terms from "./terms";
import users from "./users";
import * as gshop2 from "./gshop2";
import * as user from "./user";

export default {
    bandBanners,
    banners,
    boards,
    center,
    gshop,
    me,
    promoter,
    setting,
    shop,
    terms,
    users,
    ...gshop2,
    ...user,
};
