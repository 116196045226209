import axios from "@/plugins/axios";

let url = "/api/v1/user/me/gshop2/orders";

export const orders = {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id || data.orderNo}`).then(result => result.data);
    },
    resend(data){
        return axios.put(`${url}/${data._id}/resend`).then(result => result.data);
    }
}
