import Vue from "vue";
import VueRouter from "vue-router";
import axios from "@/plugins/axios";

import Main from "@/pages/client/Main.vue";
import { BoardRoutes } from "./board.router";

Vue.use(VueRouter);

const routes = [
    ////////////////////////////////////////////////////////////////////////////
    // 공통 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },

    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/join.vue"),
        props: true,
    },
    {
        // Terms 페이지
        path: "/terms/:code",
        component: () => import(/* webpackChunkName: "terms" */ "../pages/client/terms/TermsView.vue"),
        props: true,
    },

    {
        // 마이페이지 - 개인정보 수정
        path: "/mypage",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageMyinfoPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 개인정보 수정
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageMyinfoPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 주문내역
        path: "/mypage/orders",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageOrdersPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 주문내역
        path: "/mypage/gshop2/orders",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/gshop2/orders.vue"),
        props: true,
    },
    {
        // 마이페이지 - 주문내역 상세
        path: "/mypage/orders/:_order",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageOrdersViewPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 템플릿 관리
        path: "/mypage/template",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageTemplatePage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 템플릿 수정
        path: "/mypage/template/create",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageTemplateViewPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 템플릿 수정
        path: "/mypage/template/:_template",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageTemplateViewPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 포인트충전 내역
        path: "/mypage/point-charging",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypagePointChargingPage.vue"),
        props: true,
    },
    {
        // 마이페이지 - 1:1 문의 내역
        path: "/mypage/questions",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageQuestionsPage.vue"),
        props: true,
    },

    {
        // 개인정보처리방침
        path: "/privacy-policy",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/about/PrivacyPolicyPage.vue"),
        props: true,
    },
    {
        // 이용약관
        path: "/terms",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/about/TermsPage.vue"),
        props: true,
    },
    {
        // 서비스 소개
        path: "/about",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/about/AboutPage.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterNoticePage.vue"),
        props: true,
    },
    {
        // 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterNoticePage.vue"),
        props: true,
    },
    {
        // 문의하기
        path: "/center/inquiry",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInquiryPage.vue"),
        props: true,
    },
    {
        // 자주묻는 질문
        path: "/center/faq",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterFaqPage.vue"),
        props: true,
    },
    {
        // 나누리 상품권 구매하기
        path: "/buying",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/buying/BuyingPage.vue"),
        props: true,
    },
    {
        // 나누리 상품권 구매하기 - 주문
        path: "/buying/order",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/sub/buying/BuyingOrderPage.vue"),
        props: true,
    },
    {
        // 대한문고 자동 충전
        path: "/charge/:_charge",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/charge/index.vue"),
        props: true,
    },
    {
        // 대한문고 자동 충전
        path: "/charge2/:_order",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/charge2/index.vue"),
        props: true,
    },
    {
        path: "/gshop/giftcards",
        component: () => import(/* webpackChunkName: "gshop" */ "../pages/client/gshop/giftcards/index.vue"),
        props: true,
    },
    {
        path: "/gshop/giftcards/:_id",
        component: () => import(/* webpackChunkName: "gshop" */ "../pages/client/gshop/giftcards/[_id]/index.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 플러그인 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/plugins/nice/pay/result",
        component: () => import("../pages/plugins/nice/NICEPayResult.vue"),
        props: true,
    },
    {
        path: "/plugins/korpay/request",
        component: () => import("../pages/plugins/korpay/request.vue"),
        props: true,
    },
    {
        path: "/plugins/korpay/result",
        component: () => import("../pages/plugins/korpay/result.vue"),
    },

    ////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/dhbizadm",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
    },
    {
        // 관리자 - 대시보드
        path: "/dhbizadm/dashboard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Dashboard.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/dhbizadm/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/dhbizadm/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 게시판관리
        path: "/dhbizadm/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 -  회원직급
        path: "/dhbizadm/user-grades",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-grades/UserGradeList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원등급
        path: "/dhbizadm/user-levels",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-levels/UserLevelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원등급
        path: "/dhbizadm/withdrawn-users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/withdrawn-users/WithdrawnUserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/dhbizadm/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/dhbizadm/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 관리자 목록
        path: "/dhbizadm/admins",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admins/AdminList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리
        path: "/dhbizadm/admins/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admins/AdminView.vue"),
        props: true,
    },
    {
        // 관리자 - 관리자 상세
        path: "/dhbizadm/admins/:_admin",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/admins/AdminView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/dhbizadm/inquiries",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/inquiries/InquiryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상품관리
        path: "/dhbizadm/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상품관리
        path: "/dhbizadm/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상품관리
        path: "/dhbizadm/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 카테고리 관리
        path: "/dhbizadm/shop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/categories/CategoryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 전시 관리
        path: "/dhbizadm/shop/display",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/displays/ShopDisplayView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 전시 관리
        path: "/dhbizadm/shop/exhibitions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/exhibitions/ShopExhibitionList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 브랜드 관리
        path: "/dhbizadm/shop/brands",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/brands/BrandList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상푼권 주문 관리
        path: "/dhbizadm/gshop/orders",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/orders/OrderListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상푼권 주문 상세 페이지
        path: "/dhbizadm/gshop/orders/:_order",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/gshop/orders/OrderViewPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 정산관리
        path: "/dhbizadm/user-settlements",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 정산상세
        path: "/dhbizadm/user-settlements/:_settlement",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 적립금 관리
        path: "/dhbizadm/user-points",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-points/UserPointList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 직급포인트 관리
        path: "/dhbizadm/user-scores",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-scores/UserScoreList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 보너스 관리
        path: "/dhbizadm/user-bonuses",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-bonuses/UserBonusList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 통계관리
        path: "/dhbizadm/statistics",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/statistics/StatisticsView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 주문관리
        path: "/dhbizadm/shop/purchases/all",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PurchaseList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 미결제관리
        path: "/dhbizadm/shop/purchases/nonpayment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/NonpaymentList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 발주/발송관리
        path: "/dhbizadm/shop/purchases/payment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PaymentList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배송관리
        path: "/dhbizadm/shop/purchases/delivery",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/DeliveryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 취소관리
        path: "/dhbizadm/shop/purchases/cancel",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CancelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 반품관리
        path: "/dhbizadm/shop/purchases/exchange",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ExchangeList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 교환관리
        path: "/dhbizadm/shop/purchases/return",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ReturnList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 구매확정관리
        path: "/dhbizadm/shop/purchases/complete",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CompleteList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 코드 관리
        path: "/dhbizadm/shop/codes",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/codes/CodeList.vue"),
        props: true,
    },
    {
        // 관리자 - 쿠폰 관리
        path: "/dhbizadm/shop/coupons",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/coupons/CouponList.vue"),
        props: true,
    },
    {
        // 관리자 - 쿠폰 관리
        path: "/dhbizadm/shop/coupons/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/coupons/CouponView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쿠폰 관리
        path: "/dhbizadm/shop/coupons/:_coupon",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/coupons/CouponView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 상품문의
        path: "/dhbizadm/shop/inquires",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/inquires/InquireList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 구매후기
        path: "/dhbizadm/shop/reviews",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/reviews/ReviewList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 운영정보설정
        path: "/dhbizadm/shop/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/setting/SettingView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 공지사항
        path: "/dhbizadm/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/notifications/NotificationList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - AI챗봇
        path: "/dhbizadm/center/prompts",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/prompts/PromptListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - AI챗봇
        path: "/dhbizadm/center/prompts/:group",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/prompts/PromptListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - FAQ
        path: "/dhbizadm/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/faqs/FaqList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 1:1문의
        path: "/dhbizadm/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/questions/QuestionList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 서비스 이용약관
        path: "/dhbizadm/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/trems/TermList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리
        path: "/dhbizadm/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리
        path: "/dhbizadm/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 배너관리
        path: "/dhbizadm/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
    },
    {
        // 관리자 - 띠배너관리
        path: "/dhbizadm/band-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/band-banners/BandBannerListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 팝업관리
        path: "/dhbizadm/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popups/PopupList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 팝업관리
        path: "/dhbizadm/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/setting/SettingView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 로그
        path: "/dhbizadm/logs",
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/logs/LogList.vue'),
        props: true,
    },
    {
        path: '/dhbizadm/blacklist',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/BlackList.vue'),
        props: true
    },
    {
        path: '/dhbizadm/blacklist/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/BlackIpView.vue'),
        props: true
    },
    {
        path: '/dhbizadm/blacklist/:_ip',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/BlackIpView.vue'),
        props: true
    },
    {
        path: '/dhbizadm/whitelist',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/WhiteList.vue'),
        props: true
    },  
    {
        path: '/dhbizadm/whitelist/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/WhiteIpView.vue'),
        props: true
    },
    {
        path: '/dhbizadm/whitelist/:_ip',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/WhiteIpView.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/brands',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/brands/index.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/brands/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/brands/create.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/brands/:_id',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/brands/[_id]/index.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/categories',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/categories/index.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/giftcards',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/giftcards/index.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/giftcards/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/giftcards/create.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/giftcards/:_id',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/giftcards/[_id]/index.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/purchases/all',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/purchases/all.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/purchases/issue',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/purchases/issue.vue'),
        props: true
    },
    {
        path: '/dhbizadm/gshop2/purchases/cancel',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/gshop2/purchases/cancel.vue'),
        props: true
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = undefined;

        if (to.hash) {
            scrollTo = to.hash;
        }
        // else if (savedPosition) {
        //     scrollTo = savedPosition.y;
        // }

        if (scrollTo != undefined) {
            return {
                selector: scrollTo,
                behavior: "smooth",
            };
        } else {
            return { x: 0, y: 0 }; // Go to the top of the page if no hash
        }
    },
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";

        const payload = accessToken ? JSON.parse(atob(accessToken.split(".")[1])) || {} : {};
        const route = routes.find((route) => route.path == to.path);
        if (route?.scope) {
            if (!accessToken) throw new Error("로그인 후 이용가능합니다");

            const { scope = [] } = payload;
            if (!scope.some((scope) => route.scope.includes(scope))) throw new Error("접근권한이 없습니다");

            next();
        } else if (route?.userTypes) {
            if (!accessToken) throw new Error("로그인 후 이용가능합니다");

            const { userType } = payload;
            if (!route.userTypes.includes(userType)) throw new Error("접근권한이 없습니다");

            next();
        } else next();
    } catch (error) {
        alert(error.message);
        let path;
        if (from.path != "/") path = routes.find((route) => route.path == from.path);
        if (!path) {
            path = ["/mypage", "promoter", "/dhbizadm"].find((path) => to.path.includes(path)) || "/";
        }
        window.location.href = path;
    }
});
export default router;
