<template>
    <u-header-fixed>
        <u-header-body-basic class="d-none d-xl-block">
            <template #left>
                <logo-primary>비즈몰</logo-primary>
            </template>
            <template #center>
                <div class="px-lg-20px">
                    <u-gnb-underline gnbName="gnb" lnbTag="u-lnb-topline-border" />
                </div>
            </template>
            <template #right>
                <v-row align="center">
                    <v-col v-if="accessToken" cols="auto">
                        <txt-primary class="txt--dark font-weight-medium">내 포인트 {{ point.format() }}원</txt-primary>
                    </v-col>
                    <v-col cols="auto">
                        <user-items-primary class="font-weight-medium" />
                    </v-col>
                    <v-col cols="auto">
                        <v-btn v-bind="{ ...btn_primary }" to="/buying">상품권 발송하기</v-btn>
                    </v-col>
                </v-row>
            </template>
        </u-header-body-basic>
    </u-header-fixed>
</template>

<script>
import api from "@/api";
import { btn_primary } from "@/assets/variables";

import LogoPrimary from "@/components/publish/parents/headers/logo-primary.vue";
import UserItemsPrimary from "@/components/publish/parents/headers/user-items-primary.vue";
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";
import SitemapPrimary from "@/sets/parents/headers/sitemap-primary.vue";

import UHeaderBasic from "@/sets/styles/headers/u-header-basic.vue";
import UHeaderFixed from "@/sets/styles/headers/u-header-fixed.vue";
import UHeaderHeadBasic from "@/sets/styles/headers/u-header-head-basic.vue";
import UHeaderBodyBasic from "@/sets/styles/headers/u-header-body-basic.vue";
import UHeaderFootBasic from "@/sets/styles/headers/u-header-foot-basic.vue";

import Gnb from "@/components/publish/styles/headers/gnb.vue";
import UGnbUnderline from "@/components/publish/styles/headers/u-gnb-underline.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

export default {
    components: {
        LogoPrimary,
        UserItemsPrimary,
        BtnHamburgerPrimary,
        SitemapPrimary,

        UHeaderBasic,
        UHeaderFixed,
        UHeaderHeadBasic,
        UHeaderBodyBasic,
        UHeaderFootBasic,

        Gnb,
        UGnbUnderline,
        TxtPrimary,
    },
    data() {
        return {
            btn_primary,

            point: 0,
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            if (this.accessToken) {
                let { user } = await api.v1.me.get();
                this.point = user.point
            }
        },
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>
