export const attrs_input = {
    dense: true,
    outlined: true,
    hideDetails: "auto",
    persistentPlaceholder: true,
};
export const attrs_input__verticalTable = {
    flat: true,
    solo: true,
    class: "rounded-0",
    hideDetails: true,
    "persistent-placeholder": true,
};
export const attrs_switch = {
    inset: true,
    hideDetails: "auto",
    class: "pa-0 ma-0 ml-1 mr-n4",
};
export const attrs_input_common = {
    "hide-details": "auto",
};
//  Client attrs preset
export const attrs_controls_common = {
    "hide-details": "auto",
};
export const attrs_file_input_common = {
    "hide-details": "auto",
};
export const attrs_select_common = {
    dense: true,
    outlined: true,
    hideDetails: "auto",
    persistentPlaceholder: true,
};
export const attrs_tabs_common = {
    grow: true,
    "hide-slider": true,
};
export const attrs_window_item_common = {
    transition: "none",
    "reverse-transition": "none",
};

// Client
// Button
export const btn_primary = {
    color: "primary",
    tile: true,
};
export const btn_primary2 = {
    color: "secondary",
    tile: true,
};
export const btn_primary3 = {
    color: "grey",
    tile: true,
};
export const btn_primary4 = {
    color: "white",
    tile: true,
};
export const btn_secondary = {
    color: "grey",
    tile: true,
    outlined: true,
};
export const btn_secondary2 = {
    color: "primary",
    tile: true,
    outlined: true,
};
export const btn_tertiary = {
    color: "grey",
    tile: true,
    outlined: true,
};
export const icon_btn_primary = {
    color: "grey lighten1",
};

// Chip
export const chip_primary = {
    color: "primary",
};
export const chip_primary2 = {
    color: "secondary",
};
export const chip_primary3 = {
    color: "grey",
};

// Card
export const card_primary = {
    outlined: true,
    tile: true,
    flat: true,
};
export const card_secondary = {
    tile: true,
    flat: true,
    color: "grey lighten-5",
};

// Table
export const table_primary = {
    "hide-default-footer": true,
    "disable-filtering": true,
    "disable-pagination": true,
    "disable-sort": true,
    "mobile-breakpoint": "768",
    class: "v-data-table--line",
};
