<template>
    <div v-if="gnbName == 'gnb'" :class="gnbName + '-wrap'">
        <ul :class="[gnbName, gnbClass]">
            <template v-for="(item, index) in gnbs">
                <template v-if="item.accessShow == true ? accessToken : true">
                    <li v-if="!item.children" :key="index" :class="[gnbName + '__item ', item.className ? item.className : '']">
                        <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link'">{{ item.title }}</a>
                        <router-link v-else :to="item.path" :class="gnbName + '__link'">
                            <h2>{{ item.title }}</h2>
                        </router-link>
                        <component :is="lnbTag" class="lnb--empty"></component>
                    </li>

                    <li v-else :key="index" :class="[gnbName + '__item ', item.className ? item.className : '']">
                        <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link ' + gnbName + '__link--toggle'">{{ item.title }}</a>
                        <router-link v-else :to="item.path" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                            <h2>{{ item.title }}</h2>
                        </router-link>

                        <component :is="lnbTag">
                            <li v-for="(child, index) in item.children" :key="index">
                                <a v-if="child.href" :href="child.href" target="_blank">{{ child.title }}</a>
                                <router-link v-else :to="child.path">{{ child.title }}</router-link>
                            </li>
                        </component>
                    </li>
                </template>
            </template>
        </ul>
    </div>
    <div v-else-if="gnbName == 'gnb-mo'" :class="gnbName + '-wrap'">
        <ul :class="[gnbName, gnbClass]">
            <template v-for="(item, index) in gnbs">
                <template v-if="item.accessShow == true ? accessToken : true">
                    <li v-if="!item.children" :key="index" :class="[gnbName + '__item ', item.className ? item.className : '']">
                        <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link'">{{ item.title }}</a>
                        <router-link v-else :to="item.path" :class="gnbName + '__link'">
                            <h2>{{ accessToken }}{{ item.title }}</h2>
                        </router-link>
                    </li>

                    <li v-else :key="index" :class="[gnbName + '__item ', item.className ? item.className : '']">
                        <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link ' + gnbName + '__link--toggle'"> {{ accessToken }}{{ item.title }} </a>
                        <router-link v-else :to="item.path" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                            <h2>{{ item.title }}</h2>
                        </router-link>
                        <v-btn class="v-btn--text-fit" :class="gnbName + '__arrow'">
                            <icon-arrow-primary size="x-small" direction="down" />
                        </v-btn>

                        <ul :class="gnbName + '__sub'">
                            <template v-for="(child, index) in item.children">
                                <li v-if="!child.children" :key="index" :class="gnbName + '__sub-item'">
                                    <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link'">{{ child.title }}</a>
                                    <router-link v-else :to="child.path" :class="gnbName + '__sub-link'">
                                        {{ child.title }}
                                    </router-link>
                                </li>

                                <li v-else :key="index" :class="gnbName + '__sub-item'">
                                    <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</a>
                                    <router-link v-else :to="child.path" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</router-link>

                                    <ul :class="gnbName + '__sub'">
                                        <li v-for="grandChild in child.children" :key="grandChild.title" :class="gnbName + '__sub-item'">
                                            <a v-if="grandChild.href" :href="grandChild.href" target="_blank" :class="gnbName + '__sub-link'">{{ grandChild.title }}</a>
                                            <router-link v-else :to="grandChild.path" :class="gnbName + '__sub-link'">{{ grandChild.title }}</router-link>
                                        </li>
                                    </ul>
                                </li>
                            </template>
                        </ul>
                    </li>
                </template>
            </template>
        </ul>
    </div>
    <div v-else-if="gnbName == 'sitemap-menu'" :class="gnbName + '-wrap'">
        <ul :class="[gnbName, gnbClass]">
            <template v-for="(item, index) in gnbs">
                <li v-if="!item.children" :key="index" :class="[gnbName + '__item ', item.className ? item.className : '']">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link'">{{ item.title }}</a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link'">
                        <h2>{{ item.title }}</h2>
                    </router-link>
                </li>

                <li v-else :key="index" :class="[gnbName + '__item ', item.className ? item.className : '']">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        {{ item.title }}
                    </a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        <h2>{{ item.title }}</h2>
                    </router-link>

                    <ul :class="gnbName + '__sub'">
                        <template v-for="(child, index) in item.children">
                            <li v-if="!child.children" :key="index" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link'">
                                    {{ child.title }}
                                </router-link>
                            </li>

                            <li v-else :key="index" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</router-link>
                                <v-btn class="v-btn--text-fit" :class="gnbName + '__arrow'">
                                    <slot name="arrow" />
                                </v-btn>

                                <ul :class="gnbName + '__sub'">
                                    <li v-for="grandChild in child.children" :key="grandChild.title" :class="gnbName + '__sub-item'">
                                        <a v-if="grandChild.href" :href="grandChild.href" target="_blank" :class="gnbName + '__sub-link'">{{ grandChild.title }}</a>
                                        <router-link v-else :to="grandChild.path" :class="gnbName + '__sub-link'">{{ grandChild.title }}</router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
    <div v-else-if="gnbName == 'sub-tab'" :class="gnbName + '-wrap'">
        <v-container>
            <template v-for="item in gnbs">
                <template v-if="item.title == itemTitle">
                    <component :is="tabsTag" :key="item.title" v-bind="{ ...tabsAttrs }">
                        <component :is="tabTag" v-for="child in item.children" :key="child.title" v-bind="tabAttrs" :to="child.path" exact-path :class="[tabActive == child.title ? 'v-tab--active' : '', tabAttrs.class]">{{ child.title }}</component>
                    </component>
                </template>
            </template>
        </v-container>
    </div>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";

import Lnb from "@/components/publish/styles/headers/lnb.vue";
import ULnbToplineBorder from "@/components/publish/styles/headers/u-lnb-topline-border.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

import UTabsFilled from "@/components/publish/styles/tabs/u-tabs-filled.vue";
import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";

export default {
    props: {
        gnbName: String,
        gnbClass: String,
        lnbTag: String,

        itemTitle: String,
        tabActive: String,
        tabsTag: String,
        tabsAttrs: { type: Object, default: () => ({}) },
        tabTag: String,
        tabAttrs: { type: Object, default: () => ({}) },
    },
    components: {
        Lnb,
        ULnbToplineBorder,
        IconArrowPrimary,

        UTabsFilled,
        UTabFilled,
    },
    data: function () {
        return {
            gnbs: [
                {
                    title: "상품권 구매하기",
                    path: "/gshop/giftcards",
                },
                {
                    title: "서비스 소개",
                    path: "/about",
                },
                // {
                //     title: "포인트 충전하기",
                //     path: "/",
                // },
                {
                    title: "공지사항",
                    path: "/center/notice",
                },
                {
                    title: "문의하기",
                    path: "/center/inquiry",
                },
                {
                    title: "자주묻는 질문",
                    path: "/center/faq",
                },
            ],

            attrs_tabs_common,
        };
    },
    computed: {
        // accessToken() {
        //     return this.$store.state.accessToken;
        // },
    },
};
</script>

<style lang="scss" scoped>
// gnb
.gnb {
    display: none;
}
@media (min-width: 1200px) {
    .gnb {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        &__item {
            position: relative;
        }
        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: var(--header-body-height);
            font-size: var(--gnb-font-size);
            font-weight: var(--gnb-font-weight);
            color: var(--v-grey-base);
            padding: var(--gnb-padding-y) var(--gnb-padding-x);
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
        h2 {
            font-size: inherit;
            font-weight: inherit;
        }

        // light
        &--light {
            ::v-deep {
                .gnb__link {
                    color: #fff;
                }
            }
        }
    }
}

// gnb-mo
.gnb-mo {
    h2 {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
    &__item {
        position: relative;
        &.open {
            .gnb-mo__sub {
                height: auto;
                opacity: 1;
                visibility: visible;
                padding: 8px 0;
            }
        }
    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        height: 54px;
        font-size: 1.4rem;
        padding: 0 var(--container-gutter);
    }
    &__link--toggle {
        padding-right: calc(var(--container-gutter) + 30px);
    }
    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        width: calc(var(--container-gutter) + 30px) !important;
        height: 54px !important;
        border-radius: 0 !important;
    }
    &__sub {
        height: 0;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: all ease-out 0.2s;
        .gnb-mo__sub {
            padding: 0 0 0 var(--container-gutter) !important;
        }
    }
    &__sub-link {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        padding: 6px var(--container-gutter);
    }
}

// sitemap-menu
.sitemap-menu {
    h2 {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
    &__sub-item {
        .sitemap-menu__sub {
            height: 0;
            margin: 0;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transition: all ease-out 0.2s;
        }

        &.open {
            .sitemap-menu {
                &__sub {
                    height: auto;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
</style>
