import axios from "@/plugins/axios";
import * as profile from "./profile";
import * as gshop2 from "./gshop2";

let url = "/api/v1/me";

export const me = {
    ...profile,
    ...gshop2,

    withdraw(data) {
        return axios.post(`${url}/withdraw`, data).then((result) => result.data);
    },
};
