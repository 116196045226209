import bandBanners from "./band-banners";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import gshop from "./gshop";
import common from "./common";
import files from "./files";
import inquiries from "./inquiries";
import levels from "./levels";
import popups from "./popups";
import setting from "./setting";
import settlements from "./settlements";
import shop from "./shop";
import statistics from "./statistics";
import terms from "./terms";
import users from "./users";
import logs from "./logs";
import ip from "./ip";
import * as gshop2 from "./gshop2";

export default {
    bandBanners,
    banners,
    boards,
    center,
    gshop,
    common,
    files,
    inquiries,
    levels,
    popups,
    setting,
    settlements,
    shop,
    statistics,
    terms,
    users,
    logs,
    ip,
    ...gshop2
};
